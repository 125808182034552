.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.home-quick .btn {
    margin-top: 15px;
    width: 200px;
}

.home-quick .info {
    width: 250px;
    color: gray;
    /* font-family: 'Fira Code', monospace; */
    font-family: fangsong;
}
